<template>
  <div class="text-center w-50 mx-auto">
    <img :src="require('../../../../assets/images/12bookylogo.png')" height="80"  alt="" style="margin-top:50px; margin-bottom: 50px;">
    <div v-if="!isActive">
      <p>{{ $t('activate.password_recover') }}</p>
      <div class="row">
        <b-form-group class="col-sm-12">
          <label for="password">{{ $t('activate.password') }}:</label>
          <b-form-input id="password" :type="showInput ? 'text' : 'password'" v-model="password" :placeholder="$t('activate.password')"></b-form-input>
          <svg class="showPass" @click="showPass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>
        </b-form-group>
        <b-form-group class="col-sm-12">
          <label for="password1">{{ $t('activate.repeat_password') }}:</label>
          <b-form-input id="password1" type="password" v-model="password1" :placeholder="$t('activate.repeat_password')"></b-form-input>
        </b-form-group>
        <b-form-group class="col-sm-12" >
          <button class="btn btn-primary mt-3" @click="updatePassword">
            {{ $t('activate.update_password') }}
          </button>
        </b-form-group>
      </div>
    </div>
    <div v-if="isActive" class="d-inline-block w-100">
      <p>{{ $t('activate.password_updated') }}</p>
      <router-link :to="{ name: 'auth1.login' }">
        <button class="btn btn-primary mt-3">
          {{ $t('activate.login') }}
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'

export default {
  name: 'ConfirmMail',
  data () {
    return {
      isActive: false,
      activationToken: this.$route.params.id || '',
      showInput: false,
      password: '',
      password1: ''
    }
  },
  mounted () {
    this.checkIfActive()
  },
  methods: {
    showPass () {
      if (this.showInput === true) {
        this.showInput = false
      } else {
        this.showInput = true
      }
    },
    checkIfActive () {
      const dataPost = {
        activationToken: this.activationToken
      }
      Axios.post(API.API_CHECK_ACCOUNT_CUSTOMER_TOKEN, dataPost)
        .then((response) => {
          if (response.data.status !== 'success') {
            this.$router.push({ name: 'auth1.login' })
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          this.$router.push({ name: 'auth1.login' })
        })
    },
    updatePassword () {
      if (this.password === '') {
        core.showSnackbar('error', this.$t('activate.password_recover'))
        return 0
      } else if (this.password.length < 6) {
        core.showSnackbar('error', this.$t('activate.password_length'))
        return 0
      } else if (this.password !== this.password1) {
        core.showSnackbar('error', this.$t('activate.password_dont_match'))
        return 0
      }
      const dataPost = {
        activationToken: this.activationToken,
        password: this.password
      }
      Axios.post(API.API_RESET_CUSTOMER_PASSWORD, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isActive = true
          } else {
            this.isActive = false
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    }
  }
}
</script>

<style>
html, body{
  width: 100%;
  height: 100%;
  color: #fff !important;
}

.showPass{
  position: absolute;
  margin-top: -35px;
  right: 25px;
  cursor: pointer;
}
</style>
